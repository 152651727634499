<template>
  <div class="nongye">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/84.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">产品追溯</div>
      <div class="li-text">
        对种苗、生产、养殖、加工、流通等数据实行全程导入
        <br />采集生产过程、运输配送、批发销售等数据信息 <br />信息实时发布查询
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">环境控制</div>
      <div class="li-text">
        水质监测 <br />温湿度监测 <br />土壤成分监测 <br />光照度监测
        <br />空气质量监测
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">视频监控</div>
      <div class="li-text">
        安防监测 <br />病虫情监测 <br />种苗长势监测 <br />畜禽养殖监测
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">设备控制</div>
      <div class="li-text">
        排灌设备控制 <br />排风设备控制 <br />循环水设备控制
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">农情监控</div>
      <div class="li-text">
        旱情监控 <br />墒情监控 <br />病虫情监控 <br />灾情监控
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">农机监管</div>
      <div class="li-text">
        终端定位 <br />咨询广场 <br />农机作业查询 <br />供需信息查询
        <br />服务热线
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">移动办公</div>
    </div>
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/85.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        个人办公 <br />公共信息查询 <br />沟通交流 <br />工作流程管理
        <br />行政管理 <br />人力资源管理 <br />公文管理 <br />系统维护
        <br />移动办公
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">电子商务</div>
      <div class="li-text">
        信息查询 <br />信息发布 <br />订单查询 <br />订单查询 <br />物流状态查询
        <br />价格查询 <br />支付结算 <br />会员管理 <br />营销管理
        <br />营销管理 <br />仓储管理 <br />移动终端展示
      </div>
    </div>

    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据采集</div>
    </div>
    <div class="img mt 30">
      <img src="../../../../assets/imgs/chanping/shebei/86.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/87.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/88.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/89.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/90.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">系统应用</div>
      <div class="li-text">食品安全与质量追溯系统</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/91.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        产品信息：产品名称，规格，产地，日期
        <br />企业信息：企业介绍，企业资质，许可证
        <br />质量信息：合格证，质量等级
        <br />生产过程：工作项，时间，场所，相关认证。
        <br />投入品：投入品名称，供应商，安全等级等。
        <br />环境：生产，仓储，运输，超市等场所温湿度环境。
        <br />视频：育雏、育成、屠宰、包装等关键环节视频片段。
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">应急追溯</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/92.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">灾情疫情防控</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/93.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">远程诊断</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/94.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智能环境控制</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/95.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.nongye {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>