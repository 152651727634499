import { render, staticRenderFns } from "./zhihuiyuanqu.vue?vue&type=template&id=539fac84&scoped=true&"
import script from "./zhihuiyuanqu.vue?vue&type=script&lang=js&"
export * from "./zhihuiyuanqu.vue?vue&type=script&lang=js&"
import style0 from "./zhihuiyuanqu.vue?vue&type=style&index=0&id=539fac84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539fac84",
  null
  
)

export default component.exports