<template>
  <div class="jisan">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/101.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智慧物流</div>
      <div class="li-text">
        指挥调度 <br />数据传输 <br />货物运输 <br />供、收货管理
        <br />电子商务交易 <br />线路规划等
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智慧仓储</div>
      <div class="li-text">
        自动化仓储管理，从产品入库开始分区域，分堆放区进行货物管理，配合部署在仓库内部的湿度，温度感应器，自动对仓库进行湿度温度的调节，保证原材料及产品良好的储存状态。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智慧车辆</div>
      <div class="li-text">
        模拟现实限制条件，包括车型、车队、车辆数、服务时间，司机工作时间、要求送货时间、货品类型、数量，运输特性
        <br />针对各种运输配送模式，包括多层配送中心运输、多级联运、输入和输出物流
        <br />制定完整、连续的运输计划，优化线路、数序、装卸量
        <br />制定完整、连续的运输计划，优化线路、数序、装卸量
      </div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智慧仓储管理</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/102.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">自动化立体仓储</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/103.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智慧物流</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/104.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">智慧车辆管理</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/105.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">车辆跟踪追溯</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/106.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.jisan {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>