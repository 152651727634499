<template>
  <div class="wuliu">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/42.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">前后端系统一体化</div>
      <div class="li-text">
        前端电子商务与后端企业管理系统无缝一体，全天候商务、统一业务管理、数据不断层
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">全渠道营销</div>
      <div class="li-text">
        无缝地借助多种渠道与客户亲密互动，提供与众不同的品牌体验：面向线上线下互动的品牌企业，实现满足消费者在任何时间、任何地点、以任何方式进行购买的需求，采取实体渠道、电子商务渠道和移动电子商务渠道整合的方式销售商品或服务，提供给顾客无差别的购买体验
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">店铺管理</div>
      <div class="li-text">
        店铺SEO支持：优化部署核心关键词、品牌关键词、扩展关键词以及页面部署规则（包括首页、分类页、产品页和自定义页面等）
        <br />店铺模板选择：支持用户在品牌商城和平台商城多套模板中作出选择或自定义店铺模板，打造独特的品牌视觉体验
        <br />消息互动：网站焦点图以外，还支持以网站公告的形式发布各种促销、介绍、网购说明、交易帮助等图文混排的内容页面，并支持及时的站内消息互动
        <br />品牌互动：平台类电商可设置多形式的品牌互动专区，全面支持自营电商开辟热销商品与最新商品专区
        <br />线下店铺互动：集成百度地图，可引导线上用户向线下的转化，从而支持品牌电商设计自己的商品(或服务)的O2O业务模式
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">商品管理</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/43.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        聚合的商品内容：来源于自有、合作伙伴（多渠道的价格管理）、客户（评论与评级）；可包含文本描述、图片、视频等类型
        <br />商品自主分类：根据运营的需要，可自主设置商品管理三级分类体系
        <br />批处理操作：支持对上架、下架等批处理操作
        <br />通配性设置：引进ERP系统中BOM清单可有效解决生产制造型供应链电商平台产品适配问题或商品之间固定搭配
        <br />赠品管理、样品管理、批次管理、物料管理等功能是对纯电商系统有益的扩展与补充，对于未上ERP系统的企业尤为必要
        <br />支持各种团购模式：单人购买(聚划算)，阶梯价格、特定用户群体等团购模式
        <br />可扩展商品预售和服务预定等功能业务
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">订单管理</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/44.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        分订单：订单商品是由不同的商家进行配送，需分别结算，如同时订购自营商品与某个商家商品。
        <br />分包裹：当订单为特殊品类混合订单或含预售商品的订单。
      </div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/45.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        支持订单自动审核，提高订单执行效率
        <br />详尽追踪订单操作过程，自动识别问题订单
        <br />灵活的后台手工操作，快速响应客户需求，提升满意度
        <br />强大的复合查询 <br />高效的订单批处理操作：批量通知/取消配货
        <br />方便的订单快捷操作，全面完整的订单详情
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">促销管理</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/46.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        多样化促销类型选择
        <br />捆绑、买X赠Y，固定价格折扣，多项购买，一配一完美搭配，折扣率，完美搭配，完美搭配捆绑套餐，分布式多项购买，订单阀值更改送货方式，订单阀值固定折扣，订单阀值免费礼品，订单阀值免费优惠券，订单阀值完美搭配。
        <br />自定义定义促销模板 <br />支持刮刮卡、大转盘等营销推广工具
        <br />灵活的组合限定条件：日期限制，产品限制，类别限制，用户、用户组限制、订单限制
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">库存同步</div>
      <div class="li-text">
        通过虚拟分仓在多渠道间共享库存，避免超卖，提高库存周转率
        <br />可扩展至供应链精细化管理，根据设定的机制将供应商库存、在途库存纳入电商逻辑库存。
      </div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/47.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">仓储管理</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/48.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        可视化的仓位设置：实时洞悉仓位利用率，提升仓库运营效益，并为仓位调拨业务提供科学支撑
        <br />精细化的库存管理：供应商库存、在途、在库、在架、安全、锁定、可用等库存状态的实时监控
        <br />完整的仓库作业系统：提供从出库通知、拣货通知、拣货作业、打印、包装、复核到发货的全套配货业务支撑，从入库、调库、上架、盘点到出库的仓储作业全流程支撑
        <br />全程条码化管理：实现库位、产品和作业容器的条码化，促进仓储管理从基于“经验”向基于“标识”的方式转型
        <br />丰富的自定义功能，强大的可扩展性：自定义报表查询、自定义作业策略、自定义接口平台、自定义预警平台
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">会员管理</div>
      <div class="li-text">
        多渠道会员数据收集、线上/线下会员整合 <br />积分管理、电子券管理
        <br />买家云图：建立会员画像，精准、快速地分析用户行为习惯、消费习惯等重要商业信息
        <br />会员分组：建立RFM/ROI模型，充分发掘用户价值，实现精准化的客户维护
        <br />会员营销：凭借会员认证资料，进行常态化的短信、邮件互动
        <br />可扩展卖家云图等功能
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">交易结算</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/49.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        精细化的成本费用管控：成本中心、费用中心
        <br />销售款管理/应退款管理/预收款管理/异常款管理
        <br />结算对账：加盟商结算、分销商结算、第三方支付对账、第三方物流对账
        <br />账户管理：发票管理、保证金管理、充值提现管理
        <br />支持主流支付方式：支付宝，财付通，银联，公司转账
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">电商运营大数据</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/50.png" alt="" />
    </div>

    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">会员中心</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/51.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">卖家中心</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/52.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">营销中心</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/53.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">交易管理</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/54.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">商品管理</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/55.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">角色权限</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/56.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.wuliu {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>