<template>
  <div class="zhiyuan">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/36.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">全面整合展现协调系统信息</div>
      <div class="li-text">
        门户首页 <br />网上政务 <br />便民服务 <br />智慧应用
        <br />设计查看、受理、回复、预警、数据传输
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">管理门户</div>
      <div class="li-text">
        提供标准的系统集成接口 <br />提供统一授权管理 <br />提供统一后台管理
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">高度定制化与灵活配置</div>
      <div class="li-text">
        提供高度定制化的框架布局、门户布局、元素设定等能力，并提供对于元素可视化的开发向导功能
        <br />提供多认证模式支撑、统一单点登录和数据整合功能，真正做到以人为中心，系统、应用、数据围绕岗位的重构
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">多级门户设定</div>
      <div class="li-text">
        多种外部协同门户
        <br />多维度多层级门户 <br />信息发布内外网合一 <br />移动协同办公门户
        <br />多框架、多样式支持 <br />支持异构系统调用
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">门户展现设定</div>
      <div class="li-text">
        门户展现设定
        <br />门户布局库 <br />门户菜单库 <br />门户页面设定 <br />门户元素
        <br />门户素材库
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">门户权限设定</div>
      <div class="li-text">
        设定权限和展现权限分离：针对门户维护权限以及各级门户、元素的查看权限可以分离，使得整体门户引擎能够支持三员分离原则
        <br />门户设定权限下发：可以将门户维护的权限逐级下发，从而实现按照组织架构以及业务条线的对于门户的管理，例如：集团IT部门管理集团门户、各事业部IT管理事业部门户
        <br />框架、门户、元素多级维护权限：提供了对于整体门户框架、页面门户设定、元素设定等各级维护权限均可以进行灵活分配，从而实现细颗粒度门户应用
        <br />门户、元素多级展现权限：针对各级门户以及门户所包含元素可以按照组织架构、岗位、职级职等、安全级别等进行设定，从而保证数据在可供范围内的分享和推送
        <br />数据权限的灵活调用：各级门户、元素的数据查看权限可来源于实际业务系统或应用模块，也可以通过门户权限重新设定，从而实现数据的安全调取；
        例如：供应商门户查阅对账信息，数据权限来源于财务系统，每个供应商只能查看各自的对账信息。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">门户集成设定</div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">浏览者门户</div>
      <div class="li-text">
        公司网站 <br />对外信息发布门户 <br />对外公关门户 <br />政府关系门户
        <br />非核心员工信息门户
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">外部合作门户</div>
      <div class="li-text">
        供应商门户 <br />招投标门户 <br />合作伙伴门户 <br />客户门户
        <br />对外客服门户
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">部门门户</div>
      <div class="li-text">
        部门信息门户 <br />部门公告门户 <br />部门制度门户 <br />部门事务门户
        <br />部门绩效门户 <br />部门预算执行门户 <br />部门数据上报门户
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">专项门户</div>
      <div class="li-text">
        公文门户 <br />建设项目门户 <br />投资项目门户 <br />数据填报门户
        <br />报表门户
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.zhiyuan {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>