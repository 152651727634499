<template>
  <div class="zhonghe">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/66.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">发讯方式</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/67.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        提高领导统筹、规划能力 <br />提高政务管理水平 <br />提高政务运行效率
        <br />提高政务的软实力和竞争力
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">政务决策</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/68.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        “政务决策平台”为政府提供各种决策信息以及问题的解决方案，从而提高决策的质量和效率。在可视化的政务决策平台基础上，利用各种模型及技术对政务数据进行定性和定量的分析，为管理者提供决策依据。
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">基础办公</div>
      <div class="li-text">即时通信 <br />协同办公 <br />移动办公</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">电子公文交换</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">业务数据交换</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">并联审批</div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">政务数据交换</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/69.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        实现政府各职能部门之间的数据共享与交换
        <br />实现各职能部门与政务平台数据共享与交换内外网数据交换
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">政务服务集成</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/70.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        服务封装 <br />服务接入 <br />服务整合 <br />服务共享
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.zhonghe {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>