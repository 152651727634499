<template>
  <div class="pingan">
    <div class="jieshao">
      社会治安视频监控是以互联网宽带网络为基础，通过统一的视频监控平台、多种宽带网络和多样化的视频监控产品，以及专业的视频监控集成维护队伍组成。
    </div>
    <div class="jieshao">
      为客户提供图像和各种报警信号远程采集、传输、储存、处理的一种全新电信业务。它将分散、独立的图像采集点进行联网。实现跨区域、统一监控、统一存储和统一管理。对全区域范围公共安全监控点实时视频图像进行动态观察，实现对全系统设备和用户进行统一管理、身份认证、权限分配、配置下发等功能。
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/37.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">人脸卡口功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">交通事件检测功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">道路违法抓拍功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">车辆稽查布控功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">非现场执法</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">分析研判功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">交通事态监控功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">视频质量检测功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">智能应用管理功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">数据格式及通信功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">远程控制功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">指挥调度功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">勤务管理功能</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">设备运行状态监测功能</div>
    </div>

    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">通过视频进行行为分析和智能排查</div>
      <div class="li-text">
        运用行为分析技术对实时视频进行智能分析，
        支持穿越警戒面、进入/离开区域、区域入侵、非法停车、物品遗留、物品丢失、人员徘徊、快速移动、人员聚集等多种事件的分析检测；支持行为排查、人员排查和车辆排查功能，快速定位目标视频片段，提供视频查看效率；支持自动报警，提高监控的效率，实现智能化监控防范
      </div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/38.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">通过视频进行指挥调度</div>
      <div class="li-text">
        利用PGIS、卡口等系统，对正在发生的案事件进行现场的指挥调度；通过PGIS的地理信息，将案发时的实时视频调阅出来，并可根据地图随时切换现场视频；在犯罪嫌疑人追捕过程中，可利用卡口系统，对前端各卡口进行布控，当嫌疑车辆经过时，会向用户发出报警。
      </div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/39.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">电子警察</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/40.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">高清卡口</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/41.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.pingan {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>