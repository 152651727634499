<template>
  <div class="huanjing">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/96.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">大气监测</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">水环境监测</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">污染源监测</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">噪声监测</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">土壤及地下水监测</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">数据融合共享</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">监测分析</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">预警溯源</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">测管联动</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">智慧决策</div>
    </div>
    <div class="li">
      <div class="dian"></div>
      <div class="li-title">信息发布</div>
    </div>

    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">生态环境监测</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/97.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">水环境监测</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/98.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">污染源在线监测</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/99.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">噪声监测</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/100.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.huanjing {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>