<template>
  <div class="jiaotong">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/71.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">电子警察</div>
      <div class="li-text">
        闯红灯抓拍 <br />车牌识别 <br />图片防篡改 <br />视频录像 <br />数据传输
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">电子卡口</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">信号灯控制</div>
      <div class="li-text">
        交通信号灯配时参数的优化控制：区域协调控制、线协调控制、固定配时控制、绿波控制
        <br />单点控制：单点优化、单点多时段、黄闪、关灯、远程手动、相位锁定
        <br />交通数据自动采集与存储：各检测器、路口的车流量、占有率、排队长度等交通数据，存储在数据库
        中供系统分析调用 <br />实时监视路口的运行状态和故障状态
        <br />系统可对路口的特征参数进行联机修改
        <br />提供一组灵活的命令对系统的运行进行干预
        <br />系统提供向上的通讯接口或组件，便于指挥中心的系统集成
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">交通诱导</div>
      <div class="li-text">
        交通流数据采集 <br />可变情报板信息发布 <br />网站、交通电台等信息发布
        <br />多级控制和发布 <br />远程管理功能 <br />故障消息报警处理
        <br />基于交通流数据采集的处理及拥堵判断
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">信息发布</div>
      <div class="li-text">
        交通状态：拥堵/畅通、速度等
        <br />施工占道：施工内容、时期、地点、时间以及绕行路线等（申请、审批、发布）
        <br />交通管制：管制原因、时段、地点、管制时段、是否为临时管制、紧急程度等（申请、审批、发布）
        <br />便民服务：业务指南、法律法规等 <br />停车信息：停车场、停车位等
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">出行服务</div>
      <div class="li-text">
        位置查询 <br />实时交通信息查询 <br />公交换乘信息查询
        <br />旅游信息查询 <br />新闻信息查询
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">移动警务</div>
      <div class="li-text">
        移动采集 <br />移动执法 <br />卡口应用 <br />违章泊车 <br />及时通信
        <br />警员及车辆定位 <br />音视频会议 <br />协同办案 <br />现场取证
        <br />人员查询 <br />案件物品、地点、组织查询
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">指挥调度</div>
      <div class="li-text">
        交通警情处理 <br />警卫任务执行 <br />警力的查询和调度
        <br />协助单位的查询和调度 <br />预案方案管理
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据融合</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/72.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据管理</div>
      <div class="li-text">
        通过平台对所有数据的总量、内容、健康状况、服务业务、隶属网络的查询与监控。
      </div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/73.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">网络结构</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/74.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">保险报修</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/75.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">道路预警</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/76.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">资源整合</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/77.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.jiaotong {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>