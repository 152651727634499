<template>
  <div class="chengguan">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/78.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">基础核心应用</div>
      <div class="li-text">
        无线数据采<br />
        监督中心受理
        <br />协同工作 <br />大屏幕监督指挥 <br />综合评价
        <br />基础数据资源管理 <br />地理编码 <br />应用维护 <br />数据交换
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">扩展应用</div>
      <div class="li-text">
        视频监控 <br />移动执法 <br />门前三包 <br />呼叫中心 <br />业务短信
        <br />车载监控<br />全面城管<br />移动办公
      </div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">案件处理</div>
      <div class="li-text">
        用于案件上报（包含巡查员上报、公众上报）、案件受理、案件分发、案件处理、结果留存与通报。
      </div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/79.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">移动执法</div>
      <div class="li-text">
        实时了解巡逻车周边环境、事故处理点情况、车辆所处位置，接收车辆实时抓拍的车牌数据等，并通过双向通话与集群对讲下达指令、接收信息、紧急调度。
      </div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/80.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/81.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">GIS应用</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/82.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">路面及地下管网监控</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/83.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.chengguan {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>