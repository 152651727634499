<template>
  <div class="yuanqu">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/47.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">园区监控</div>
      <div class="li-text">
        安保监控 <br />环境监控 <br />节能监控 <br />交通监控
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">园区管理</div>
      <div class="li-text">
        物业管理 <br />企业管理 <br />企业管理 <br />招商引资<br />资源管理
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">园区服务</div>
      <div class="li-text">
        政企互动 <br />物流服务 <br />电子商务 <br />信息展示
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">园区指挥、展示</div>
      <div class="li-text">应急指挥调度<br />电子沙盘 <br />电子地图</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">运维管理</div>
      <div class="li-text">设备状态监测及管理<br />运行维护管理</div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">园区门户</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/58.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">周界监控</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/59.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">环境监控</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/60.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">安全监控</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/61.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">节能管理</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/62.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">沙盘推演</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/63.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">GIS展示</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/64.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">集中监控</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/65.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.yuanqu {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>